import React, {FunctionComponent, useEffect, useState} from 'react';
import {ColumnLayout, Container, Box, Button} from "aws-northstar";
import Stack from "aws-northstar/layouts/Stack";
import './styles.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "../../authConfig";

import {IUserInfo} from "../../interfaces";
import {useDispatch} from "react-redux";

import {
    storeUserInfoAction
} from "../../redux/actions";
import ApiHandler from "../../common/api";
import {useHistory} from "react-router-dom";

const Homepage: FunctionComponent = () => {
    return <Stack>
        <HomepageContent/>
    </Stack>
}

// The content in the main content area of the App layout
export function HomepageContent() {

    const [request, setRequest] = useState(false);
    const [review, setReview] = useState(false);
    const [audit, setAudit] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    function createAccountMap(roles: string[]) {
        const accountMap = new Map();
        for (const role of roles) {
            if (role === 'BreakGlass.Reviewer') {
                setReview(true);
                ApiHandler.reviewer = true;
            } else if (role === 'BreakGlass.Auditor') {
                setAudit(true)
                ApiHandler.auditor = true;
            } else if (role === 'BreakGlass.Admin') {
                setReview(true);
                ApiHandler.reviewer = true;
                setAudit(true)
                ApiHandler.auditor = true;
            } else {
                const words = role.split('.');
                const account = words[2]
                const awsrole = words[1]
                if (accountMap.has(account)) {
                    accountMap.get(account).push(awsrole)
                } else {
                    const awsroles: Array<string> = [];
                    awsroles.push(awsrole);
                    accountMap.set(account, awsroles)
                }
                setRequest(true);
                ApiHandler.requester = true;
            }
        }
        return accountMap;
    }

    const { login } = useMsalAuthentication(InteractionType.Popup, loginRequest);

    const { accounts } = useMsal();

    useEffect(() => {
        if(accounts){
            secinfo();
            //console.log(result);        
        }
    }, [accounts]);

    const secinfo = async () => {

        const userInfo: IUserInfo = {
            user: "",

            requester: false,
            reviewer: false,
            auditor: false,

            accountMap: new Map([])
        }

        userInfo.user = accounts[0]?.username ? accounts[0]?.username : "";
        userInfo.accountMap = createAccountMap(accounts[0]?.idTokenClaims?.roles ? accounts[0]?.idTokenClaims?.roles: [] );

        userInfo.requester = request;
        userInfo.reviewer = review;
        userInfo.auditor = audit;

        //console.log(userInfo)
        dispatch(storeUserInfoAction(userInfo));
    }

    function handleLogin() {
        login(InteractionType.Popup, loginRequest);
    }

    const onOpenClick = () => {
        const link = getLink();
        history.push(link);
    }

    const getLink = () => {
        if (request) {
            return "/Request-dashboard";
        } else if (review) {
            return "/Review-dashboard";
        } else if (audit) {
            return "/Audit-dashboard"
        } else {
            return "/"
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="awsui-grid awsui-util-p-s">
                    <div className="awsui-util-pt-xxl awsui-row">
                        <div
                            className="custom-home-main-content-area col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">

                            <Container headingVariant='h4'
                                    title="Security & Control">
                                <div className="awsui-util-container back_ground_white text_black border_black">
                                    <h1 className="awsui-text-large">
                                        <strong>&nbsp;&nbsp;ADP Breakglass Broker</strong>
                                    </h1>
                                    <div><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allow users to request
                                        temporary elevated access to your AWS environment</strong></div>
                                    <div>
                                        <br/>
                                    </div>
                                    <AuthenticatedTemplate>
                                        <Box>
                                            &nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={onOpenClick}>Open
                                            dashboard</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Box>
                                    </AuthenticatedTemplate>
                                    <UnauthenticatedTemplate>
                                        <Box>
                                            &nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={handleLogin}>Login
                                            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Box>                                   
                                    </UnauthenticatedTemplate>
                                    <div className="awsui-util-container-header">
                                        {/*<h2>How it works</h2>*/}
                                    </div>
                                </div>
                            </Container>

                            <Container headingVariant='h4'
                                    title="Operating Procedure">
                                <div className="awsui-util-container">

                                    <div>
                                        <ColumnLayout>

                                            <div data-awsui-column-layout-root={true}>
                                                <ul>
                                                    <li>
                                                        Users can request temporary elevated access to your AWS environment
                                                        if they are eligible to do so, based on their role assignment in
                                                        the Azure. Please speak to the adp cloudops team to gain access.
                                                    </li>

                                                    <li>
                                                        While raising a request, a user is prompted to supply additional
                                                        information
                                                        <ul>
                                                            <li> By default, the tool prompts the user for a free-text
                                                                justification field and a duration.
                                                            </li>
                                                            <li>
                                                                The justification should contain the change/incident ticket relating to the request. 
                                                            </li>
                                                            <li>
                                                                The duration is the length of time during which they can invoke sessions. It does not affect the length of each session.
                                                            </li>

                                                        </ul>
                                                    </li>

                                                    <li>
                                                        Once raised, a request is evaluated to determine whether it will be
                                                        approved or rejected. Once raised, a request is evaluated to determine
                                                        whether it will be approved or rejected
                                                        <ul>
                                                            <li>The default evaluation process is a simple, single-step
                                                                human approval
                                                            </li>
                                                            <li>
                                                                An approver is any user who belongs to a reviewer role
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        Users are notified when their requests are approved or rejected
                                                    </li>

                                                    <li>
                                                        A user can log in and see their previous requests, including the
                                                        approval status of each request they have raised
                                                    </li>

                                                    <li>
                                                        From the time when a user's request is approved to when the
                                                        requested duration ends:
                                                        <ul>
                                                            <li>The user can click the "Access console" button next to that
                                                                request, to invoke a session in the AWS Management Console
                                                                using the approved IAM role and AWS account
                                                            </li>
                                                            <li>The user can also click the "CLI" button next to that
                                                                request to obtain temporary credentials which they can use
                                                                with the AWS CLI, for the same role and account
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        Each session lasts 1 hour
                                                    </li>

                                                    <li>
                                                        A user can invoke as many sessions as they need to, for the duration
                                                        of their approved request
                                                    </li>

                                                    <li>
                                                        When the elevated access period ends, the user can no longer invoke
                                                        sessions
                                                        <ul>
                                                            <li>If they need further access they must raise another
                                                                request
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        Users can raise multiple concurrent requests for different
                                                        role-account combinations, as long as they are eligible
                                                    </li>
                                                </ul>

                                            </div>

                                        </ColumnLayout>
                                    </div>
                                </div>
                            </Container>

                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Homepage;
