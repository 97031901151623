import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";

export default class ApiHandler {

    api_ep: string

    private _resources: string[];

    public static requester = false;
    public static reviewer = false;
    public static auditor = false;

    constructor(api_url: string, resources: string[]) {
        this.api_ep = api_url;
        this._resources = resources;
    }

    private get_resource_url(resource_name: string) {
        return this.api_ep + "/v1/" + resource_name
    }

    public to_url_params(params: KeyValue<string, string>[]): string {
        let result = ""
        params.forEach(param => {
            result += param.key.concat("=").concat(param.value).concat("&")
        })
        return result.slice(0, -1);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async get_authorized_resource<T>(resource_name: string, apiMethod: ApiMethod, body_params?: any, url_params?: KeyValue<string, string>[]): Promise<T> {

        if (this._resources.indexOf(resource_name) > -1) {

            let url = this.get_resource_url(resource_name)
            if (url_params) {
                const url_params_text = this.to_url_params(url_params)
                if (url_params_text !== "") {
                    url = url + "?" + this.to_url_params(url_params)
                }
            }

            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const tokenresponse = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });

            const response = await fetch(url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + tokenresponse.idToken
                    },
                    method: apiMethod,
                    body: body_params == null ? null : JSON.stringify(body_params),
                    mode: 'cors'
                });
            if (!response.ok) {
                const error = await response.json();
                console.error(error);
                if (error.message) {
                    throw new Error(error.message);
                } else {
                    throw new Error(String(error));
                }
            }
            return response.json() as Promise<T>;
        }
        else {
            console.log("error calling get_resource, resource name not configured!");
            return Promise.reject<T>();
        }
    }
}

export enum ApiMethod {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE"
}

export type KeyValue<T, U> = {
    key: T,
    value: U,
};

