import {BrowserRouter as Router, Route} from 'react-router-dom';
import NorthStarThemeProvider from 'aws-northstar/components/NorthStarThemeProvider';
import AppLayout from "./components/AppLayout";
import {HomepageContent} from "./components/home/HomePageContent";
import RequestDashboard from "./components/Request/RequestDashboard";
import ReviewDashboard from "./components/Review/ReviewDashboard";
import AuditDashboard from "./components/Audit/AuditDashboard";
import RequestForm from "./components/Request/RequestForm";

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

type AppProps = {
    msalInstance: IPublicClientApplication
};

const App = ({ msalInstance }: AppProps) => {

    return (
        <MsalProvider instance={msalInstance}>
            <NorthStarThemeProvider>
                <Router>
                    <Route exact path='/'>
                            <AppLayout>
                                <HomepageContent/>
                            </AppLayout>;
                    </Route>
                    <Route exact path='/Request-dashboard'>
                        <MsalAuthenticationTemplate interactionType={InteractionType.Popup} authenticationRequest={loginRequest}>
                            <AppLayout>
                                <RequestDashboard/>
                            </AppLayout>;
                        </MsalAuthenticationTemplate>
                    </Route>
                    <Route exact path='/Review-dashboard'>
                        <MsalAuthenticationTemplate interactionType={InteractionType.Popup} authenticationRequest={loginRequest}>
                            <AppLayout>
                                <ReviewDashboard/>
                            </AppLayout>;
                        </MsalAuthenticationTemplate>
                    </Route>
                    <Route exact path='/Audit-dashboard'>
                        <MsalAuthenticationTemplate interactionType={InteractionType.Popup} authenticationRequest={loginRequest}>
                            <AppLayout>
                                <AuditDashboard/>
                            </AppLayout>;
                        </MsalAuthenticationTemplate>
                    </Route>
                    <Route exact path='/Create-request'>
                        <MsalAuthenticationTemplate interactionType={InteractionType.Popup} authenticationRequest={loginRequest}>
                            <AppLayout>
                                <RequestForm/>
                            </AppLayout>;
                        </MsalAuthenticationTemplate>
                    </Route>
                </Router>
            </NorthStarThemeProvider>
        </MsalProvider>
    );
}

export default App;
