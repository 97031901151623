import ApiHandler, {ApiMethod} from '../common/api'
import {IRequest, ICredential} from '../interfaces/index'
import {BG_ENDPOINTS} from '../config/index'

export const api = new ApiHandler(
    BG_ENDPOINTS.Endpoint,
    BG_ENDPOINTS.Resources
);

export const getRequests = (user_params?:any) => api.get_authorized_resource<IRequest[]>(
    "request/mine", ApiMethod.GET,null,[])

export const getPendingRequests = (user_params?:any) => api.get_authorized_resource<IRequest[]>(
    "review/pending", ApiMethod.GET,null,[])

export const getProcessedRequests = (user_params?:any) => api.get_authorized_resource<IRequest[]>(
    "review/processed", ApiMethod.GET,null,[])

export const getAllRequests = (user_params?:any) => api.get_authorized_resource<IRequest[]>(
    "audit/requests", ApiMethod.GET,null,[])

export const createRequest = (request_account:any, request_role:any, request_duration:any, request_justification:any, user_params?:any) => api.get_authorized_resource<any>(
    "request/create", ApiMethod.POST, {request_account: request_account, request_role: request_role, request_duration: request_duration, request_justification: request_justification}, [])

export const deleteRequest = (id:any, request_time:any, user_params?:any) => api.get_authorized_resource<any>(
    "request/delete", ApiMethod.POST, {id: id, request_time: request_time}, [])

export const approveRequest = (id:any, request_time:any, request_duration:any, reviewer:any, user_params?:any) => api.get_authorized_resource<any>(
    "review/approve", ApiMethod.POST, {id: id, request_time: request_time, request_duration: request_duration, reviewer: reviewer}, [])

export const rejectRequest = (id:any, request_time:any, reviewer:any, user_params?:any) => api.get_authorized_resource<any>(
    "review/reject", ApiMethod.POST, {id: id, request_time: request_time, reviewer: reviewer}, [])

export const updateRequestURL = (id:any, request_time:any, request_url:any, user_params?:any) => api.get_authorized_resource<any>(
    "update_request_url", ApiMethod.POST, {id: id, request_time: request_time, request_url: request_url}, [])

export const invokeFederateConsole = (account:any, role:any, user_params?:any) => api.get_authorized_resource<any>(
    "federate/console", ApiMethod.GET,null,[{key:"account", value:account}, {key:"role", value:role}])

export const invokeFederateCli = (account:any, role:any, user_params?:any) => api.get_authorized_resource<ICredential>(
    "federate/cli", ApiMethod.GET,null,[{key:"account", value:account}, {key:"role", value:role}])
