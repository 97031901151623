import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore, { history } from './redux/Store';
import {ConnectedRouter} from "connected-react-router";
import { Provider } from "react-redux";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const store = configureStore();

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App msalInstance={msalInstance} />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
  );
});

