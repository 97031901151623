import React, {FunctionComponent, useMemo} from 'react';
import AppLayoutBase from 'aws-northstar/layouts/AppLayout';
import HeaderBase from 'aws-northstar/components/Header';
import SideNavigationBase, { SideNavigationItem, SideNavigationItemType } from 'aws-northstar/components/SideNavigation';
import BreadcrumbGroup from 'aws-northstar/components/BreadcrumbGroup';

import ApiHandler from "../../common/api";

const AppLayout: FunctionComponent = ( {children} ) => {

    const Header = useMemo(() => (
        <HeaderBase title="ADP Breakglass Broker" />
    ), []);
    const Breadcrumbs = useMemo(() => <BreadcrumbGroup rootPath=""/>, []);
    const SideNavigation = useMemo(() => {

        return <SideNavigationBase
            header={{text: 'Menu', href: '/'}}
            items={
            getNavigation()
            }
            ></SideNavigationBase>
    }, []);

  function getNavigation() {
    const navs: Array<SideNavigationItem> = [];

    if (ApiHandler.requester) {
      const nav:SideNavigationItem = {text: 'Request dashboard', type: SideNavigationItemType.LINK, href: '/Request-dashboard'}
      navs.push(nav)
    }

    if (ApiHandler.reviewer) {
      const nav:SideNavigationItem = {text: 'Review dashboard', type: SideNavigationItemType.LINK, href: '/Review-dashboard'}
      navs.push(nav)
    }

    if (ApiHandler.auditor) {
      const nav:SideNavigationItem = {text: 'Audit dashboard', type: SideNavigationItemType.LINK, href: '/Audit-dashboard'}
      navs.push(nav)
    }

    return navs;
  }

    return <AppLayoutBase
        header={Header}
        navigation={SideNavigation}
        breadcrumbs={Breadcrumbs}
    >
        {children}
    </AppLayoutBase>
}

export default AppLayout;